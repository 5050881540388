.resource-detail-modal {
  transition: all 333ms ease;
}

.resource-detail-modal--content {
  background-color: #FFF;
  padding: 2rem;
  margin: 2rem;
  margin-top: -5rem;
  box-shadow: 0px -4px 10px rgba(0,0,0,0.4);
  border-radius: 3px;
  position: relative;
  z-index: 1;
}

.resource-detail-modal--markdown {
  img {
    max-width: 80%;
    margin: 1rem auto;
    display: block;
    border: 1px solid #AAA;
  }
  h2 {
    margin-top: 3rem;
  }
}