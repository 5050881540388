@import "styles/page.scss";
@import "styles/post-detail.scss";
@import "styles/project-detail.scss";
@import "styles/pages/about";
@import "styles/pages/landing-page-a";
@import "styles/pages/landing-page-b";
@import "styles/pages/resource-detail-modal";
@import "styles/editor";

@import "styles/animations.scss";
@import "styles/utility";
@import "styles/forms";
@import "styles/buttons";

@import "styles/hero";
@import "styles/v2";

@import "styles/components/timeline";
@import "styles/components/slider";
@import "styles/components/image-uploader";
@import "styles/components/markdown-editor";

@import "styles/page-specific";

@import "styles/responsive";

@import "styles/box-3d";
@import "styles/scroll-position-indicator";

@import url("https://fonts.googleapis.com/css?family=Exo|Farro|Roboto|Roboto+Mono|Abril+Fatface|Merriweather|Signika+Negative&display=swap");

html, body {
  min-height: 100%;
  display: flex;
  width: 100%;
  margin: 0;
  font-family: "Roboto Mono", sans-serif;
}
body {
  transition: all 333ms ease-in-out;
}
#root {
  width: 100%;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "Exo", sans-serif;
}
p, li {
  font-family: "Roboto Mono", sans-serif;
  line-height: 1.6em;
  font-size: 18px;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
a {
  text-decoration: none;
}
