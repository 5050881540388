$material-red-500: #F44336;
$material-red-a700: #D50000;
$material-pink-400: #EC407A;
$material-pink-500: #E91E63;
$material-pink-a400: #F50057;
$material-purple-500: #9C27B0;
$material-purple-a700: #AA00FF;
$material-deep-purple-500: #673AB7;
$material-deep-purple-a400: #651FFF;
$material-indigo-500: #3F51B5;
$material-indigo-a700: #304FFE;
$material-blue-500: #2196F3;
$material-blue-a700: #2962FF;
$material-light-blue-500: #03A9F4;
$material-light-blue-a700: #0091EA;
$material-cyan-500: #00BCD4;
$material-cyan-a700: #00B8D4;
$material-teal-200: #03DAC5;
$material-teal-500: #009688;
$material-teal-a700: #00BFA5;
$material-green-500: #4CAF50;
$material-green-a700: #00C853;
$material-light-green-500: #8BC34A;
$material-light-green-a700: #64DD17;
$material-lime-500: #CDDC39;
$material-lime-a700: #AEEA00;
$material-yellow-500: #FFEB3B;
$material-yellow-a700: #FFD600;
$material-amber-500: #FFC107;
$material-amber-a700: #FFAB00;
$material-orange-500: #FF9800;
$material-orange-a700: #FF6D00;
$material-deep-orange-500: #FF5722;
$material-deep-orange-a700: #DD2C00;
$material-blue-gray-500: #607D8B;
