.text-slider {
  margin: 0 15px;
  position: relative;
  min-height: 122px;
}

.slider-button {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 20px;
  background-color: $material-green-500;
  color: white;
  transition: all 222ms ease;
  &:hover {
    background-color: $material-light-green-500;
    transform: translateY(-40%);
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
.next-button {
  right: -20px;
}
.previous-button {
  left: -20px;
}

.slider-item p {
  padding: 0px 15px;
}


.slider-item {
  border: 1px solid #efefef;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.25);
  padding: 10px 0px;
  margin: 0px 0px;
  transition: all 222ms ease;
  float: left;
  clear: both;
  width: 100%;
  background-color: #FFF;
}
.slider-item--title {
  font-weight: bold;
  text-transform: uppercase;
}
.slider-item--content {
  padding: 0px 40px;
  margin: 0px 10px;
}

.slider-animation {
  transition: all 222ms ease;
  display: inline;
}
.slider-animation-enter {
  transform: translateX(-300px);
  opacity: 0;
}
.slider-animation-exit {
  transform: translateX(300px);
  opacity: 0;
  position: absolute;
}

.progress {
  margin: 10px auto;
  text-align: center;
  clear: both;
  padding-top: 15px;
}
.progress-item {
  display: inline-block;
  color: #AAA;
  padding-left: 5px;
}
