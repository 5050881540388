@import 'material-colors';
.posts .page-list-item--highlighted {
  background-color: $material-blue-a700;
  color: white;
}
.projects .page-list-item--highlighted {
  background-color: $material-purple-a700;
  color: white;
}
.navigation .page-list-item--highlighted {
  background-color: $material-teal-a700;
  color: white;
}
