.resource-detail-page {
  padding: 0px;
}

.resource-detail-page .page {
  background-color: #FFF;
}
.resource-detail--image-header img {
  width: 100%;
}
.resource-detail--content {
  padding: 15px;
}
.resource-detail--content img {
  max-width: 100%;
}
.resource-detail--header {
  padding: 15px;
  h1 {
    margin-top: 0px;
  }
}
.resource-detail--posted-at {
  margin: 0px;
  font-size: 14px;
  color: #797979;
}
.resource-detail--header--snippet {
  font-style: italic;
}
.resource-detail--tags {
  padding: 0px 10px;
}
.tag {
  display: inline-block;
  border-radius: 5px;
  width: auto;
  padding: 5px 15px;
  margin: 5px;
}
.tag p {
  margin: 0;
}

// .articlePage.isMag .organism.contentStream>div>p:first-child:first-letter
// font: 200px/140px "Effra",Helvetica,Arial,sans-serif;
// float: left;
// font: 100px/78px "Effra",Helvetica,Arial,sans-serif;
// font-weight: 700;
