.text-input {
  display: block;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin: 5px 0px 15px;

  font-size: 20px;
  font-family: "Roboto Mono", sans-serif;

  color: #575757;
  background-color: #fff;

  border: 1px solid #CCC;
  border-radius: 2px;
}

.form-inline-checkbox {
  display: inline-block;
}
.checkbox {
  height: 20px;
  width: 20px;
  background-color: #000;
  border: 2px solid #FFF;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  margin: 0px 10px;
}
.delete-icon {
  height: 40px;
  width: 40px;
  margin: 0px 5px;
  border-radius: 40px;
  font-size: 20px;
  text-align: center;
  background-color: #ee2222;
  color: #fff;
  cursor: pointer;
  transition: all 111ms ease;
  &:hover {
    background-color: #ff0000;
    transform: translateY(3px);
  }
  svg {
    padding-top: 10px;
  }
}
.save-button {
  height: 40px;
  width: 40px;
  margin: 0px 5px;
  border: none;
  border-radius: 40px;
  font-size: 20px;
  text-align: center;
  background-color: #22ee22;
  color: #fff;
  cursor: pointer;
  transition: all 111ms ease;
  &:hover {
    background-color: #00ff00;
    transform: translateY(3px);
  }
}
