@import 'material-colors.scss';

.page--container {
  height: 100vh;
  padding: 15px;
  transition: all 333ms ease-in-out;
}
.page {
  max-width: 900px;
  position: relative;
  z-index: 2;
}
.page--header {
  padding: 15px;
  background-color: white;
  color: #575757;
  border-radius: 4px;
  transition: all 666ms ease;
}
.page--title {
  margin: 0;
  transition: all 666ms ease;
}
.page--content {
  background-color: #FFF;
  padding: 15px;
  margin: 15px 0px;
}
.page-list-item {
  border-radius: 2px;
  display: block;
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(255,255,255,0.75);
  color: #575757; // override in each page
  text-decoration: none;
  transition: all 333ms ease;
  cursor: pointer;
}
.page-list-item:hover {
  background-color: rgba(255,255,255,0.9);
  color: #575757;
  transform: scale(1.1);
}
.list-item-highlight {
  background-color: rgba(#000, 0.75);
  color: #FFF;
}

.page-header--back-button {
  font-size: 24px;
  padding: 0px 25px;
  background-color: rgba(#007aff,0.75);
  color: #fff;
  border-radius: 2px;
  margin-right: 5px;
  transition: all 333ms ease;
  cursor: pointer;
}
.page-header--back-button:hover {
  background-color: rgba(#007aff,0.9);
  color: #FFF;
  transform: scale(1.1);
}
