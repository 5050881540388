.landing-a {
  background-image: url('/face-wrap-small.jpg') !important;
  background-size: cover !important;
  background-position: center !important;
}

.landing-page-a {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding-bottom: 20px;
}

.landing-page-a--content {
  width: 100%;
  padding-bottom:50px;
  .page--header {
    margin: 0px 25px;
  }
}
.landing-page-a--enter-button {
  cursor: pointer;
  background-image: url(/face-wrap-small.jpg) !important;
  background-size: cover;
  background-position: center;
  border: 4px solid #000;
  box-shadow: inset 0px 0px 40px 10px #000;
  display: block;
  text-align: center;
  color: white;
  padding: 2px;
  margin-top: 5px;
  margin: 5px 25px;
  border-radius: 4px;
}
